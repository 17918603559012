module.exports = {
	'pmi:main-nav': require('c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/main-nav/controller.js'),
	'pmi:market-nav-with-menu': require('c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/market-nav-with-menu/controller.js'),
	'pmi:key-survey': ()=>import(/* webpackChunkName: "components--key-survey" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/key-survey/controller.js'),
	'pmi:search': require('c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/search/controller.js'),
	'pmi:medium-text-on-image': ()=>import(/* webpackChunkName: "components--medium-text-on-image" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/medium-text-on-image/controller.js'),
	'pmi:small-text-on-image': ()=>import(/* webpackChunkName: "components--small-text-on-image" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/small-text-on-image/controller.js'),
	'pmi:unsmoke-small-text-on-image': ()=>import(/* webpackChunkName: "components--unsmoke-small-text-on-image" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/unsmoke-small-text-on-image/controller.js'),
	'pmi:footer-list': ()=>import(/* webpackChunkName: "components--footer-list" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/footer-list/controller.js'),
	'pmi:footer-nav': ()=>import(/* webpackChunkName: "components--footer-nav" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/footer-nav/controller.js'),
	'pmi:button-widget': ()=>import(/* webpackChunkName: "components--button-widget" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/button-widget/controller.js'),
	'pmi:market-contact-map': ()=>import(/* webpackChunkName: "components--market-contact-map" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/market-contact-map/controller.js'),
	'pmi:small-written-by': ()=>import(/* webpackChunkName: "components--small-written-by" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/small-written-by/controller.js'),
	'pmi:content-block-wysiwyg': ()=>import(/* webpackChunkName: "components--content-block-wysiwyg" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/content-block-wysiwyg/controller.js'),
	'pmi:topic-highlight': ()=>import(/* webpackChunkName: "components--topic-highlight" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/topic-highlight/controller.js'),
	'pmi:banner-widget': ()=>import(/* webpackChunkName: "components--banner-widget" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/banner-widget/controller.js'),
	'pmi:investor-highlight': ()=>import(/* webpackChunkName: "components--investor-highlight" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/investor-highlight/controller.js'),
	'pmi:basic-picture-widget': ()=>import(/* webpackChunkName: "components--basic-picture-widget" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/basic-picture-widget/controller.js'),
	'pmi:editors-pick-widget': ()=>import(/* webpackChunkName: "components--editors-pick-widget" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/editors-pick-widget/controller.js'),
	'pmi:latest-press-releases': ()=>import(/* webpackChunkName: "components--latest-press-releases" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/latest-press-releases/controller.js'),
	'pmi:section-highlight': ()=>import(/* webpackChunkName: "components--section-highlight" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/section-highlight/controller.js'),
	'pmi:large-image-text-on-image': ()=>import(/* webpackChunkName: "components--large-image-text-on-image" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/large-image-text-on-image/controller.js'),
	'pmi:small-image-above-title': ()=>import(/* webpackChunkName: "components--small-image-above-title" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/small-image-above-title/controller.js'),
	'pmi:videos-highlight': ()=>import(/* webpackChunkName: "components--videos-highlight" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/videos-highlight/controller.js'),
	'pmi:video-carousel-pop-up': ()=>import(/* webpackChunkName: "components--video-carousel-pop-up" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/video-carousel-pop-up/controller.js'),
	'pmi:key-figures-icon': ()=>import(/* webpackChunkName: "components--key-figures-icon" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/key-figures-icon/controller.js'),
	'pmi:tag-results': ()=>import(/* webpackChunkName: "components--tag-results" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/tag-results/controller.js'),
	'pmi:related-category': ()=>import(/* webpackChunkName: "components--related-category" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/related-category/controller.js'),
	'pmi:search-box-results-page': ()=>import(/* webpackChunkName: "components--search-box-results-page" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/search-box-results-page/controller.js'),
	'pmi:social-share': ()=>import(/* webpackChunkName: "components--social-share" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/social-share/controller.js'),
	'pmi:social-share-collapse': ()=>import(/* webpackChunkName: "components--social-share-collapse" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/social-share-collapse/controller.js'),
	'pmi:highlight-text-on-image': ()=>import(/* webpackChunkName: "components--highlight-text-on-image" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/highlight-text-on-image/controller.js'),
	'pmi:highlight-image': ()=>import(/* webpackChunkName: "components--highlight-image" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/highlight-image/controller.js'),
	'pmi:initiative': ()=>import(/* webpackChunkName: "components--initiative" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/initiative/controller.js'),
	'pmi:article-carousel': ()=>import(/* webpackChunkName: "components--article-carousel" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/article-carousel/controller.js'),
	'pmi:timeline-widget': ()=>import(/* webpackChunkName: "components--timeline-widget" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/timeline-widget/controller.js'),
	'pmi:job-details': ()=>import(/* webpackChunkName: "components--job-details" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/job-details/controller.js'),
	'pmi:sub-navigation': require('c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/sub-navigation/controller.js'),
	'pmi:job-list': ()=>import(/* webpackChunkName: "components--job-list" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/job-list/controller.js'),
	'pmi:glossary': ()=>import(/* webpackChunkName: "components--glossary" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/glossary/controller.js'),
	'pmi:error-404-widget': ()=>import(/* webpackChunkName: "components--error-404-widget" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/error-404-widget/controller.js'),
	'pmi:faq': ()=>import(/* webpackChunkName: "components--faq" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/faq/controller.js'),
	'pmi:related-faq': ()=>import(/* webpackChunkName: "components--related-faq" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/related-faq/controller.js'),
	'pmi:show-more-widget': ()=>import(/* webpackChunkName: "components--show-more-widget" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/show-more-widget/controller.js'),
	'pmi:tobacco-economics': ()=>import(/* webpackChunkName: "components--tobacco-economics" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/tobacco-economics/controller.js'),
	'pmi:conditions-of-use': ()=>import(/* webpackChunkName: "components--conditions-of-use" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/conditions-of-use/controller.js'),
	'pmi:ingredient-finder': ()=>import(/* webpackChunkName: "components--ingredient-finder" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/ingredient-finder/controller.js'),
	'pmi:social-highlight': ()=>import(/* webpackChunkName: "components--social-highlight" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/social-highlight/controller.js'),
	'pmi:video-highlight-full-width': ()=>import(/* webpackChunkName: "components--video-highlight-full-width" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/video-highlight-full-width/controller.js'),
	'pmi:sec-filing': ()=>import(/* webpackChunkName: "components--sec-filing" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/sec-filing/controller.js'),
	'pmi:press-release-details-widget': ()=>import(/* webpackChunkName: "components--press-release-details-widget" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/press-release-details-widget/controller.js'),
	'pmi:specific-event-highlight': ()=>import(/* webpackChunkName: "components--specific-event-highlight" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/specific-event-highlight/controller.js'),
	'pmi:table-widget': ()=>import(/* webpackChunkName: "components--table-widget" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/table-widget/controller.js'),
	'pmi:table-widget-ir': ()=>import(/* webpackChunkName: "components--table-widget-ir" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/table-widget-ir/controller.js'),
	'pmi:market-redirect-widget': ()=>import(/* webpackChunkName: "components--market-redirect-widget" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/market-redirect-widget/controller.js'),
	'pmi:investor-cta-widget': ()=>import(/* webpackChunkName: "components--investor-cta-widget" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/investor-cta-widget/controller.js'),
	'pmi:investor-cta-widget-blue': ()=>import(/* webpackChunkName: "components--investor-cta-widget-blue" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/investor-cta-widget-blue/controller.js'),
	'pmi:ir-email-alerts-widget': ()=>import(/* webpackChunkName: "components--ir-email-alerts-widget" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/ir-email-alerts-widget/controller.js'),
	'pmi:quarterly-reports': ()=>import(/* webpackChunkName: "components--quarterly-reports" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/quarterly-reports/controller.js'),
	'pmi:sustainability': ()=>import(/* webpackChunkName: "components--sustainability" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/sustainability/controller.js'),
	'pmi:tbe-journey': ()=>import(/* webpackChunkName: "components--tbe-journey" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/tbe-journey/controller.js'),
	'pmi:ir-annual-reports': ()=>import(/* webpackChunkName: "components--ir-annual-reports" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/ir-annual-reports/controller.js'),
	'pmi:ir-share-history-calculator': ()=>import(/* webpackChunkName: "components--ir-share-history-calculator" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/ir-share-history-calculator/controller.js'),
	'pmi:ir-event-highlight': ()=>import(/* webpackChunkName: "components--ir-event-highlight" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/ir-event-highlight/controller.js'),
	'pmi:ir-event-detail-widget': ()=>import(/* webpackChunkName: "components--ir-event-detail-widget" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/ir-event-detail-widget/controller.js'),
	'pmi:ir-search': ()=>import(/* webpackChunkName: "components--ir-search" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/ir-search/controller.js'),
	'pmi:ir-search-results': ()=>import(/* webpackChunkName: "components--ir-search-results" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/ir-search-results/controller.js'),
	'pmi:ir-share-price-overview': ()=>import(/* webpackChunkName: "components--ir-share-price-overview" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/ir-share-price-overview/controller.js'),
	'pmi:sustainability-filter': ()=>import(/* webpackChunkName: "components--sustainability-filter" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/sustainability-filter/controller.js'),
	'pmi:social-media-highlights': ()=>import(/* webpackChunkName: "components--social-media-highlights" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/social-media-highlights/controller.js'),
	'pmi:content-button': ()=>import(/* webpackChunkName: "components--content-button" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/content-button/controller.js'),
	'pmi:interactive-wheel': ()=>import(/* webpackChunkName: "components--interactive-wheel" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/interactive-wheel/controller.js'),
	'pmi:blog-carousel': ()=>import(/* webpackChunkName: "components--blog-carousel" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/blog-carousel/controller.js'),
	'pmi:sustainability-qa-block': ()=>import(/* webpackChunkName: "components--sustainability-qa-block" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/sustainability-qa-block/controller.js'),
	'pmi:audio-widget': ()=>import(/* webpackChunkName: "components--audio-widget" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/audio-widget/controller.js'),
	'pmi:leadership-widget': ()=>import(/* webpackChunkName: "components--leadership-widget" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/leadership-widget/controller.js'),
	'pmi:interactive-map': ()=>import(/* webpackChunkName: "components--interactive-map" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/interactive-map/controller.js'),
	'pmi:dropdown-menu-widget': ()=>import(/* webpackChunkName: "components--dropdown-menu-widget" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/dropdown-menu-widget/controller.js'),
	'pmi:pop-up-newsletter': ()=>import(/* webpackChunkName: "components--pop-up-newsletter" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/pop-up-newsletter/controller.js'),
	'pmi:animations': ()=>import(/* webpackChunkName: "components--animations" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/animations/controller.js'),
	'pmi:animations-rive-widget': ()=>import(/* webpackChunkName: "components--animations-rive-widget" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/animations-rive-widget/controller.js'),
	'pmi:newsroom': ()=>import(/* webpackChunkName: "components--newsroom" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/newsroom/controller.js'),
	'pmi:image-fade': ()=>import(/* webpackChunkName: "components--image-fade" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/image-fade/controller.js'),
	'pmi:downloadpopup-widget': ()=>import(/* webpackChunkName: "components--downloadpopup-widget" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/downloadpopup-widget/controller.js'),
	'pmi:latest-article-popup-widget': ()=>import(/* webpackChunkName: "components--latest-article-popup-widget" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/widgets/latest-article-popup-widget/controller.js'),
	'pmi:article-intro-partial': ()=>import(/* webpackChunkName: "components--article-intro-partial" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/article-intro-partial/controller.js'),
	'pmi:drop-down': require('c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/drop-down/controller.js'),
	'pmi:world': require('c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/world/controller.js'),
	'pmi:large-image-text-on-image-partial': ()=>import(/* webpackChunkName: "components--large-image-text-on-image-partial" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/large-image-text-on-image-partial/controller.js'),
	'pmi:small-image-above-title-partial': ()=>import(/* webpackChunkName: "components--small-image-above-title-partial" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/small-image-above-title-partial/controller.js'),
	'pmi:breadcrumbs-partial': ()=>import(/* webpackChunkName: "components--breadcrumbs-partial" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/breadcrumbs-partial/controller.js'),
	'pmi:pages-nav': ()=>import(/* webpackChunkName: "components--pages-nav" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/pages-nav/controller.js'),
	'pmi:search-results-partial': ()=>import(/* webpackChunkName: "components--search-results-partial" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/search-results-partial/controller.js'),
	'pmi:job-search-partial': ()=>import(/* webpackChunkName: "components--job-search-partial" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/job-search-partial/controller.js'),
	'pmi:video': ()=>import(/* webpackChunkName: "components--video" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/video/controller.js'),
	'pmi:check-drop-down': ()=>import(/* webpackChunkName: "components--check-drop-down" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/check-drop-down/controller.js'),
	'pmi:ie-warning': ()=>import(/* webpackChunkName: "components--ie-warning" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/ie-warning/controller.js'),
	'pmi:highlight-text-on-image-partial': ()=>import(/* webpackChunkName: "components--highlight-text-on-image-partial" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/highlight-text-on-image-partial/controller.js'),
	'pmi:highlight-image-partial': ()=>import(/* webpackChunkName: "components--highlight-image-partial" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/highlight-image-partial/controller.js'),
	'pmi:topic-highlight-partial': ()=>import(/* webpackChunkName: "components--topic-highlight-partial" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/topic-highlight-partial/controller.js'),
	'pmi:search-field-drop': require('c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/search-field-drop/controller.js'),
	'pmi:mobile-scroll-up': require('c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/mobile-scroll-up/controller.js'),
	'pmi:carousel-highlight': ()=>import(/* webpackChunkName: "components--carousel-highlight" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/carousel-highlight/controller.js'),
	'pmi:job-list-pages': ()=>import(/* webpackChunkName: "components--job-list-pages" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/job-list-pages/controller.js'),
	'pmi:modal-video': ()=>import(/* webpackChunkName: "components--modal-video" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/modal-video/controller.js'),
	'pmi:basic-video-partial': ()=>import(/* webpackChunkName: "components--basic-video-partial" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/basic-video-partial/controller.js'),
	'pmi:country-specific-info-partial': ()=>import(/* webpackChunkName: "components--country-specific-info-partial" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/country-specific-info-partial/controller.js'),
	'pmi:faq-block': ()=>import(/* webpackChunkName: "components--faq-block" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/faq-block/controller.js'),
	'pmi:scrollable-content': ()=>import(/* webpackChunkName: "components--scrollable-content" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/scrollable-content/controller.js'),
	'pmi:page-info-widget-image-partial': ()=>import(/* webpackChunkName: "components--page-info-widget-image-partial" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/page-info-widget-image-partial/controller.js'),
	'pmi:gallery-item': ()=>import(/* webpackChunkName: "components--gallery-item" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/gallery-item/controller.js'),
	'pmi:unfoldable-list': ()=>import(/* webpackChunkName: "components--unfoldable-list" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/unfoldable-list/controller.js'),
	'pmi:tab-widget': ()=>import(/* webpackChunkName: "components--tab-widget" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/tab-widget/controller.js'),
	'pmi:investor-events-overview': ()=>import(/* webpackChunkName: "components--investor-events-overview" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/investor-events-overview/controller.js'),
	'pmi:calendar-menu': ()=>import(/* webpackChunkName: "components--calendar-menu" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/calendar-menu/controller.js'),
	'pmi:document-list-partial': ()=>import(/* webpackChunkName: "components--document-list-partial" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/document-list-partial/controller.js'),
	'pmi:market-search-menu': require('c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/market-search-menu/controller.js'),
	'pmi:related-articles-partial': ()=>import(/* webpackChunkName: "components--related-articles-partial" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/related-articles-partial/controller.js'),
	'pmi:social-list': ()=>import(/* webpackChunkName: "components--social-list" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/social-list/controller.js'),
	'pmi:document-download-partial': ()=>import(/* webpackChunkName: "components--document-download-partial" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/document-download-partial/controller.js'),
	'pmi:news-releases-article': ()=>import(/* webpackChunkName: "components--news-releases-article" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/news-releases-article/controller.js'),
	'pmi:news-releases-block': ()=>import(/* webpackChunkName: "components--news-releases-block" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/news-releases-block/controller.js'),
	'pmi:event-highlight-partial': ()=>import(/* webpackChunkName: "components--event-highlight-partial" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/event-highlight-partial/controller.js'),
	'pmi:age-gate-partial': ()=>import(/* webpackChunkName: "components--age-gate-partial" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/age-gate-partial/controller.js'),
	'pmi:filters-partial': ()=>import(/* webpackChunkName: "components--filters-partial" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/filters-partial/controller.js'),
	'pmi:null-check': ()=>import(/* webpackChunkName: "components--null-check" */'c:/j/executors/4/workspace/pmi.com_release_4.49.0/PublishPackage/frontend-v3/src/components/pmicom/components/null-check/controller.js')
};
